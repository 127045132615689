.navbar-silly-wrapper {
  display: flex;
  justify-content: space-between; /* Keep the logo and order button at opposite ends */
  align-items: center;
  padding: 0 20px; /* Maintain padding inside the navbar */
}

.logo-goofy-spacing {
  margin-right: 2px; /* Add space to the right of the logo */
}

.order-now-wacky-margin {
  margin-left: 20px; /* Add space to the left of the "Order Now" button */
}

.home-reviews-section {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
  overflow: hidden;
}

.home-review-carousel {
  display: flex;
  overflow: hidden;
}

.home-review-track {
  display: flex;
  animation: home-scroll 100s linear infinite; /* Adjusted duration for 25 cards */
}

.home-review-card {
  display: inline-block;
  width: 300px;
  height: 300px;
  margin: 20px 15px; /* Added 20px margin to top and bottom */
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden; /* Ensures content stays within the card */
  text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
  white-space: normal; /* Allows the text to wrap within the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the text vertically */
}

@keyframes home-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.home-order-now-button {
  text-align: center;
  margin-top: 10px; /* Adjust to position the button closer or further from the text */
}

.home-order-button:hover {
  background-color: #d90429; /* Darken the color on hover */
}

.home-button-link {
  text-decoration: none;
}
/* Increase Button Size */
.home-order-button {
  background-color: #ef233c; /* Button color */
  color: white;
  padding: 15px 30px; /* Increased padding for a bigger button */
  border: none;
  border-radius: 8px; /* Slightly more rounded corners */
  cursor: pointer;
  font-size: 18px; /* Increased font size */
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
  animation: pulse 2s infinite;
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Button Hover Effect */
.home-order-button:hover {
  background-color: #d90429; /* Slightly darker color on hover */
}

/* Updates Section Header */
.home-updates-section-header {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Update Card Container */
.home-update-card-wrapper {
  margin-bottom: 20px; /* Increase space between cards */
}

/* Update Card */
.home-update-card {
  padding: 20px;
  background-color: #f9f9f9; /* Subtle background color */
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-update-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Update Card Title */
.home-update-card-title {
  font-size: 24px;
  color: #333;
}

/* Update Card Label */
.home-update-card-label {
  background-color: #ef233c; /* Example label background color */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}
body {
  cursor: url('/build/external/cursor.png'), auto; /* Fallback to auto cursor */
}







/* floidev css additions above */

.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  height: 139px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ffd7cd;
}
.home-navbar-interactive {
  width: 1448px;
  height: 135px;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
  background-color: #ffd7cd;
}
.home-pasted-image {
  top: -119px;
  left: 18px;
  width: 314px;
  height: 308px;
  right:250px;
  position: absolute;
}
.home-desktop-menu {
  flex: 1;
  width: 903px;
  height: 37px;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  width: 1014px;
  height: 124px;
  display: flex;
  margin-left: 50px;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-nav2 {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav2:hover {
  color: #3d6e70ff;
}
.home-nav6 {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav6:hover {
  color: #3d6e70ff;
}
.home-text:hover {
  color: #3d6e70ff;
}
.home-nav3 {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav3:hover {
  color: #3d6e70ff;
}
.home-nav4 {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav4:hover {
  color: #3d6e70ff;
}
.home-nav5 {
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav5:hover {
  color: #3d6e70ff;
}
.home-text02:hover {
  color: #3d6e70ff;
}
.home-buttons {
  width: 182px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-thq-dropdown {
  width: 35px;
  cursor: pointer;
  height: 24px;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle {
  top: -19px;
  fill: #595959;
  left: -385px;
  color: #ffffff;
  width: 175px;
  height: 55px;
  display: inline-flex;
  position:relative;
  margin-left:50px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: 45px;
  padding-right: 52px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: #291477;
}
.home-dropdown-arrow {
  top: 31px;
  right: 49px;
  position: absolute;
  transition: 0.3s;
}
.home-container01 {
  flex: 0 0 auto;
  width: 105px;
  height: 21px;
  display: flex;
  flex-direction: column;
}
.home-text04 {
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-right: 4px;
  vertical-align: middle;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-icon {
  fill: #D9D9D9;
  width: 32px;
  height: 28px;
  transition: 0.3s;
}
.home-dropdown-list {
  top: 20px;
  left: -279px;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text05 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text07 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-burger-menu {
  top: 56px;
  right: 260px;
  display: none;
  position: absolute;
}
.home-icon2 {
  width: 24px;
  height: 24px;
  opacity: 0;
}
.home-mobile-menu {
  width: 100%;
  height: 300px;
  display: none;
  padding: 32px;
  z-index: 100;
  align-self: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.home-nav {
  height: 251px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo {
  font-size: 2em;
  font-weight: bold;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon4 {
  fill: var(--dl-color-gray-900);
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-updates {
  color: var(--dl-color-gray-white);
  width: 132px;
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-updates:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-buttons2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-about-us-mobile {
  color: var(--dl-color-gray-white);
  width: 132px;
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-about-us-mobile:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-buttons3 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-menu {
  color: var(--dl-color-gray-white);
  width: 132px;
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-menu:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-text18:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-buttons4 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-gallery {
  color: var(--dl-color-gray-white);
  width: 132px;
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-gallery:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-text21:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-buttons5 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-contact-us {
  color: var(--dl-color-gray-white);
  width: 132px;
  transition: 0.3s;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  padding-left: 1.5rem;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-primary1-blue100);
}
.home-contact-us:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-text24:hover {
  border-color: #849492ff;
  background-color: #849492ff;
}
.home-hero {
  width: 100%;
  height: 521px;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url('https://media-cdn.grubhub.com/image/upload/d_search:browse-images:default.jpg/w_1200,q_auto,fl_lossy,dpr_auto,c_fill,f_auto,h_800,g_auto/yczsv4cctya70hhm0ukr');
}
.home-container03 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  color: var(--dl-color-gray-white);
  width: 762px;
  height: 134px;
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  line-height: 2;
}
.home-hero-sub-heading {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 1.6;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-update-us {
  width: 100%;
  height: 1125px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-update-us1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 1844px;
  height: 1157px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-gallery-heading {
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.home-blog {
  width: 1324px;
  height: 907px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-container04 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-container05 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-pricing1 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-details {
  width: 100%;
  height: 510px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-container06 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text28 {
  color: var(--dl-color-primary1-blue80);
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-details-image {
  width: 429px;
  height: 400px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fourunits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  border-top-right-radius: var(--dl-radius-radius-round);
}
.home-pricing2 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-menu1 {
  width: 100%;
  height: 973px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-container07 {
  flex: 1;
  width: 100%;
  height: 914px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-details-heading1 {
  width: 139px;
  height: 63px;
  align-self: center;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container08 {
  width: 100%;
  height: 821px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.home-container09 {
  flex: 0 0 auto;
  width: 627px;
  height: 816px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image1 {
  width: 632px;
  height: 817px;
}
.home-container10 {
  flex: 0 0 auto;
  width: 983px;
  height: 567px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-pasted-image2 {
  width: 971px;
  height: 544px;
  align-self: center;
}
.home-pricing3 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-gallery1 {
  width: 100%;
  height: 1185px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-gallery2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-gallery-heading1 {
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.home-gallery-sub-heading {
  color: rgb(153, 153, 153);
  text-align: center;
  line-height: 1.6;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-container11 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  margin-top: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-pricing4 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-details2 {
  width: 100%;
  height: 1127px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-details3 {
  width: 1483px;
  height: 1287px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-container12 {
  flex: 1;
  width: 1366px;
  height: 1092px;
  display: flex;
  position: relative;
  flex-direction: column;
}
.home-gallery-heading2 {
  height: 78px;
  align-self: center;
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.home-iframe {
  width: var(--dl-size-size-maxwidth);
  height: 537px;
  align-self: center;
}
.home-container13 {
  width: 100%;
  height: 293px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  justify-content: flex-start;
}
.home-container14 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text31 {
  width: 237px;
  height: 55px;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-link3 {
  color: var(--dl-color-gray-white);
  width: 167px;
  height: 47px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: rgb(41, 20, 119);
}
.home-text34 {
  width: auto;
  height: var(--dl-size-size-large);
}
.home-text38 {
  width: auto;
  height: var(--dl-size-size-large);
}
.home-text39 {
  font-weight: 700;
}
.home-container15 {
  flex: 0 0 auto;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text42 {
  width: 126px;
  height: 57px;
  font-family: "Raleway";
}
.home-link4 {
  color: var(--dl-color-gray-white);
  width: 167px;
  height: 47px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: rgb(41, 20, 119);
}
.home-container16 {
  flex: 0 0 auto;
  width: 431px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-text55 {
  width: 242px;
  height: 236px;
  align-self: center;
  font-family: "Raleway";
}
.home-text58 {
  align-self: center;
}
.home-pricing5 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-footer1 {
  width: 100%;
  height: 203px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container18 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-nav1 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-decoration: none;
}
.home-nav61 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav21 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav31 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav41 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav51 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container20 {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container21 {
  flex: 0 0 auto;
  width: 404px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container22 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text80 {
  color: var(--dl-color-secondary-gray500);
}
.home-icon-group {
  width: 56px;
  height: 123px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-link5 {
  display: contents;
}
.home-icon6 {
  fill: var(--dl-color-secondary-gray500);
  width: 40px;
  height: 40px;
  align-self: flex-end;
  margin-right: 6px;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .home-pasted-image {
    top: -94px;
    left: 80px;
    width: 264px;
    height: 259px;
  }
  .home-dropdown-toggle {
    top: -18px;
    left: -259px;
  }
  .home-dropdown-list {
    top: 21px;
    left: -136px;
  }
  .home-burger-menu {
    top: 56px;
    right: 100px;
    position: absolute;
  }
  .home-icon2 {
    opacity: 0;
  }
  .home-hero {
    height: 437px;
  }
  .home-update-us {
    height: 1124px;
  }
  .home-update-us1 {
    height: 1079px;
  }
  .home-details {
    height: 539px;
  }
  .home-details1 {
    height: 454px;
  }
  .home-menu1 {
    height: 759px;
  }
  .home-container07 {
    height: 926px;
  }
  .home-container08 {
    width: 1488px;
    height: 645px;
  }
  .home-container09 {
    width: 500px;
    height: 650px;
  }
  .home-pasted-image1 {
    width: 501px;
    height: 646px;
  }
  .home-container10 {
    width: 778px;
    height: 426px;
  }
  .home-pasted-image2 {
    width: 753px;
    height: 414px;
  }
  .home-details2 {
    height: 1122px;
  }
  .home-details3 {
    width: 1227px;
    height: 1019px;
    padding-top: 0px;
  }
  .home-container12 {
    width: 1227px;
  }
  .home-iframe {
    width: 1227px;
  }
  .home-container16 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 1200px) {
  .home-navbar-interactive {
    width: 968px;
  }
  .home-pasted-image {
    top: -61px;
    left: -72px;
    width: 202px;
    height: 197px;
  }
  .home-links {
    width: 620px;
    height: 41px;
  }
  .home-dropdown-toggle {
    top: -17px;
    left: -100px;
    width: 159px;
  }
  .home-dropdown-list {
    top: 4px;
    left: 16px;
  }
  .home-icon2 {
    opacity: 0;
  }
  .home-hero {
    height: 339px;
  }
  .home-hero-heading {
    color: var(--dl-color-gray-white);
    font-size: 60px;
    font-family: Raleway;
    line-height: 2;
  }
  .home-update-us {
    height: 1039px;
  }
  .home-update-us1 {
    height: 1072px;
  }
  .home-blog {
    width: 1170px;
  }
  .home-details {
    height: 462px;
    padding-bottom: 0px;
  }
  .home-details1 {
    width: 992px;
    height: 348px;
    margin-top: 0px;
  }
  .home-container06 {
    width: 539px;
    height: 315px;
    padding-bottom: 0px;
  }
  .home-details-heading {
    width: 444px;
    height: 156px;
    font-size: 34px;
    font-family: Raleway;
    line-height: 1.6;
    margin-right: 0px;
  }
  .home-details-sub-heading {
    color: var(--dl-color-gray-800);
    font-size: 15px;
    line-height: 1.6;
  }
  .home-details-image {
    width: 318px;
    height: 307px;
  }
  .home-container07 {
    height: 778px;
  }
  .home-container08 {
    width: 992px;
  }
  .home-container09 {
    width: 408px;
    height: 499px;
  }
  .home-pasted-image1 {
    width: 370px;
    height: 477px;
  }
  .home-container10 {
    width: 578px;
    height: 316px;
  }
  .home-pasted-image2 {
    width: 560px;
    height: 307px;
  }
  .home-gallery2 {
    height: 100%;
  }
  .home-details2 {
    height: 1024px;
  }
  .home-details3 {
    width: 995px;
    height: 100%;
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-container12 {
    width: 995px;
    height: 968px;
    margin-right: 0px;
  }
  .home-iframe {
    width: 992px;
    height: 521px;
    margin-right: 0px;
  }
  .home-container14 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-link3 {
    color: var(--dl-color-gray-white);
    background-color: rgb(41, 20, 119);
  }
  .home-container15 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-container16 {
    width: 335px;
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer1 {
    width: 1026px;
    height: 218px;
  }
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    width: 786px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-end;
  }
  .home-pasted-image {
    top: -59px;
    left: -2px;
    position: absolute;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-dropdown-toggle {
    top: -20px;
    left: -147px;
  }
  .home-dropdown-list {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    position: static;
    justify-content: flex-start;
  }
  .home-icon2 {
    fill: #ffffff;
    width: 46px;
    height: 34px;
    opacity: 1;
    align-self: flex-end;
  }
  .home-mobile-menu {
    width: 100%;
    height: auto;
    padding: 32px;
    align-self: flex-start;
  }
  .home-nav {
    width: 625px;
    height: 388px;
  }
  .home-logo {
    font-size: 2em;
    font-weight: bold;
  }
  .home-container03 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    color: var(--dl-color-gray-white);
    font-size: 55px;
    max-width: auto;
    text-align: center;
    font-family: Raleway;
    line-height: 2;
  }
  .home-text26 {
    text-align: center;
  }
  .home-hero-sub-heading {
    font-size: 20px;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-update-us {
    height: 1114px;
  }
  .home-update-us1 {
    height: 1112px;
  }
  .home-blog {
    width: 959px;
  }
  .home-details1 {
    width: 771px;
  }
  .home-container06 {
    width: 517px;
    height: 432px;
  }
  .home-details-sub-heading {
    color: var(--dl-color-gray-800);
    font-size: 18px;
    align-self: flex-start;
    text-align: left;
    line-height: 1.6;
  }
  .home-details-image {
    width: 217px;
    height: 205px;
    margin-left: 0px;
  }
  .home-menu1 {
    height: 1024px;
    margin-bottom: 2px;
    padding-bottom: 0px;
  }
  .home-container07 {
    height: 814px;
    padding-bottom: 0px;
  }
  .home-container08 {
    width: 771px;
    height: 659px;
  }
  .home-container09 {
    width: 322px;
    height: 394px;
    position: relative;
  }
  .home-pasted-image1 {
    right: -195px;
    width: 286px;
    bottom: -255px;
    height: 366px;
    position: absolute;
  }
  .home-container10 {
    width: 451px;
    height: 324px;
    position: relative;
  }
  .home-pasted-image2 {
    top: -143px;
    left: -285px;
    width: 704px;
    height: 382px;
    position: absolute;
  }
  .home-gallery1 {
    height: 1514px;
  }
  .home-gallery2 {
    height: 100%;
  }
  .home-gallery-sub-heading {
    text-align: center;
  }
  .home-container11 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .home-details2 {
    height: 1124px;
  }
  .home-details3 {
    width: 771px;
    height: 1067px;
  }
  .home-container12 {
    width: 771px;
  }
  .home-iframe {
    width: 891px;
  }
  .home-container16 {
    width: 260px;
  }
  .home-footer1 {
    width: 731px;
    margin-right: 0px;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    width: 515px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-pasted-image {
    top: -57px;
    left: 26px;
  }
  .home-burger-menu {
    top: 23px;
    left: 169px;
  }
  .home-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-nav {
    width: 369px;
  }
  .home-hero {
    height: 260px;
  }
  .home-container03 {
    height: 191px;
  }
  .home-hero-heading {
    height: 90px;
    font-size: 45px;
  }
  .home-hero-sub-heading {
    font-size: 18px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-update-us {
    height: 1497px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-update-us1 {
    width: 737px;
    height: 1545px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-blog {
    width: 714px;
    height: 1397px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-details {
    height: 776px;
  }
  .home-details1 {
    width: 767px;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-container06 {
    align-items: center;
    justify-content: flex-start;
  }
  .home-text28 {
    text-align: center;
  }
  .home-details-heading {
    text-align: center;
  }
  .home-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-details-image {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .home-menu1 {
    height: 1227px;
  }
  .home-container07 {
    width: 767px;
    height: 1112px;
    align-items: center;
    margin-right: 0px;
    justify-content: flex-start;
  }
  .home-details-heading1 {
    text-align: center;
  }
  .home-container09 {
    width: 247px;
    height: 733px;
    position: relative;
    margin-right: 1px;
  }
  .home-pasted-image1 {
    left: 84px;
    width: 545px;
    bottom: -375px;
    height: 699px;
    position: absolute;
  }
  .home-container10 {
    width: 447px;
    height: 286px;
    position: relative;
  }
  .home-pasted-image2 {
    top: -101px;
    left: -151px;
    width: 498px;
    height: 270px;
    position: absolute;
  }
  .home-gallery1 {
    height: 2155px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-gallery2 {
    height: 2191px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-gallery-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container11 {
    grid-template-columns: 1fr 1fr;
  }
  .home-details2 {
    height: 1632px;
  }
  .home-details3 {
    width: 709px;
    height: 1507px;
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-container12 {
    width: 767px;
    height: 1487px;
    align-items: center;
    margin-bottom: 0px;
    justify-content: flex-start;
  }
  .home-iframe {
    width: 482px;
  }
  .home-container13 {
    width: 482px;
    height: 273px;
    position: relative;
  }
  .home-container14 {
    left: 156px;
    bottom: -39px;
    position: absolute;
  }
  .home-container15 {
    left: 156px;
    bottom: -250px;
    height: 245px;
    position: absolute;
  }
  .home-container16 {
    left: 121px;
    width: 252px;
    bottom: -551px;
    position: absolute;
  }
  .home-footer {
    height: 233px;
  }
  .home-footer1 {
    width: 712px;
    height: 228px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container19 {
    width: 767px;
    height: 67px;
    align-items: center;
    margin-right: var(--dl-space-space-halfunit);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container22 {
    width: 515px;
    height: 38px;
    position: relative;
    align-items: flex-start;
    justify-content: center;
  }
  .home-text80 {
    color: var(--dl-color-secondary-gray500);
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
  }
  .home-text81 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-header {
    height: 104px;
  }
  .home-navbar-interactive {
    width: 90%;
    height: 101px;
    padding: var(--dl-space-space-unit);
  }
  .home-pasted-image {
    top: -71px;
    left: 11px;
  }
  .home-burger-menu {
    top: 9px;
    left: 16px;
  }
  .home-icon2 {
    right: 0px;
    opacity: 1;
    position: static;
  }
  .home-mobile-menu {
    top: -25px;
    right: -40px;
    width: auto;
    padding: 16px;
    align-self: flex-start;
  }
  .home-nav {
    width: 263px;
    height: 327px;
  }
  .home-logo {
    font-size: 1.5em;
  }
  .home-icon4 {
    fill: var(--dl-color-gray-900);
  }
  .home-updates {
    width: var(--dl-size-size-large);
  }
  .home-about-us-mobile {
    width: var(--dl-size-size-large);
    height: 37px;
  }
  .home-menu {
    width: var(--dl-size-size-large);
  }
  .home-gallery {
    width: var(--dl-size-size-large);
  }
  .home-contact-us {
    width: var(--dl-size-size-large);
  }
  .home-container03 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero-heading {
    color: var(--dl-color-gray-white);
    width: 400px;
    font-size: 35px;
    font-family: Raleway;
    line-height: 2;
  }
  .home-hero-sub-heading {
    color: var(--dl-color-gray-white);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6;
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-update-us {
    height: 1124px;
  }
  .home-update-us1 {
    height: 1074px;
    padding: var(--dl-space-space-unit);
    position: relative;
  }
  .home-blog {
    width: 463px;
    height: 1583px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container05 {
    opacity: 0;
  }
  .home-details {
    height: 871px;
    padding-bottom: 0px;
  }
  .home-details1 {
    width: 304px;
    height: 100%;
  }
  .home-container06 {
    width: 262px;
    height: 449px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .home-text28 {
    color: var(--dl-color-primary1-blue80);
    font-family: Raleway;
  }
  .home-details-heading {
    width: 255px;
    font-size: 22px;
    font-family: Raleway;
    line-height: 1.6;
  }
  .home-details-sub-heading {
    color: var(--dl-color-gray-800);
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: 1.6;
  }
  .home-details-image {
    width: 217px;
    height: 209px;
  }
  .home-menu1 {
    height: 777px;
    padding-top: 0px;
  }
  .home-container07 {
    height: 774px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container08 {
    width: 320px;
    height: 431px;
    position: relative;
  }
  .home-container09 {
    width: 121px;
    height: 230px;
  }
  .home-pasted-image1 {
    left: 120px;
    width: 320px;
    bottom: -279px;
    height: 410px;
    position: absolute;
  }
  .home-container10 {
    width: 444px;
    height: 242px;
    position: relative;
  }
  .home-pasted-image2 {
    top: -85px;
    left: -2px;
    width: 320px;
    height: 173px;
    position: absolute;
  }
  .home-gallery1 {
    height: 1124px;
  }
  .home-gallery2 {
    height: 1120px;
    padding: var(--dl-space-space-unit);
    position: relative;
  }
  .home-container11 {
    width: 372px;
    height: 862px;
    position: relative;
  }
  .home-details2 {
    height: 1431px;
    margin-top: 0px;
    padding-top: 4px;
  }
  .home-details3 {
    width: 479px;
    height: 1382px;
    padding-top: 0px;
  }
  .home-container12 {
    width: 479px;
    height: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .home-iframe {
    width: 417px;
    height: 402px;
  }
  .home-container13 {
    width: 479px;
    position: relative;
  }
  .home-container14 {
    top: 44px;
    left: 76px;
    width: 328px;
    height: 212px;
    position: absolute;
    padding-top: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-text31 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container15 {
    left: 106px;
    width: 264px;
    bottom: -217px;
    position: absolute;
  }
  .home-container16 {
    left: 0px;
    right: 0px;
    bottom: -478px;
    margin: auto;
    position: absolute;
  }
  .home-footer {
    width: 100%;
    height: 274px;
  }
  .home-footer1 {
    width: 255px;
    height: 250px;
    padding: var(--dl-space-space-unit);
  }
  .home-nav1 {
    width: 293px;
    height: 63px;
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container19 {
    width: 417px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-container20 {
    width: 249px;
  }
  .home-container21 {
    width: 320px;
    height: 57px;
  }
  .home-container22 {
    justify-content: flex-start;
  }
  .home-text80 {
    color: var(--dl-color-secondary-gray500);
    font-size: 14px;
    text-align: left;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text81 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-icon-group {
    height: 243px;
  }
}
