.gallery-card3-gallery-card {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card3-image {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 300px;
  position: absolute;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}




































@media(max-width: 479px) {
  .gallery-card3-gallery-card {
    width: 181px;
    height: 136px;
    align-items: flex-start;
  }
  .gallery-card3-image {
    left: 3px;
    width: 173px;
    bottom: 4px;
    height: 125px;
  }
}
